import React from 'react'
import useGoogleFonts from "@qoohoo/common/hooks/useGoogleFonts";
import SEO from "../SEO";
import CustomNavBar from "components/templates/custom/CustomNavBar";
import CreatorFooter from "../../CreatorFooter";
import Hero from './components/Hero';
import About from "./components/About";
import Offerings from './components/Offerings';
import Reviews from './components/Reviews';
import Collage from './components/Collage';
import WorkSamples from './components/WorkSamples';
import CustomHamMenu from '../custom/CustomHamMenu';
import UserAuthDropdown from 'components/Dropdown/Auth/UserAuthDropdown';


const Template10 = (props) => {
  const { channelDetails, creator, customTemplateConfig } = props;

  useGoogleFonts([{fontFamily: "Oregano"}]);

  const renderSection = (config, index) => {
    let _Section;
    
    switch(config.type) {
      case "HERO":
        _Section = Hero;
        break;
      case "OFFERINGS":
        _Section = Offerings;
        break;
      case "CUSTOMER_REVIEWS":
        _Section = Reviews;
        break;
      case "ABOUT":
        _Section = About;
        break;
      case "COLLAGE":
        _Section = Collage;
        break;
      case "WORK_SAMPLES":
        _Section = WorkSamples;
        break;
    }

    return (
      <_Section
        id={config.id}
        key={index}
        creator={creator}
        data={config.data}
      />
    );
  }

  return (
    <div className='font-nunito bg-white'>
      <SEO seoConfig={customTemplateConfig.seo} />
      <CustomNavBar
        showAuth={false}
        showHamAuth={false}
        creator={creator}
        isDark={true}
        customLinks={customTemplateConfig.navLinks}
        customTitle={customTemplateConfig.navTitle}
        customTemplateConfig={customTemplateConfig}
        theme = {{
          primaryColor: "#FFFFFF",
          background: "#B27F6F",
          auth: {
            loginButtonTheme: {
              background: "#3A3A3A",
              primaryColor: "#FFFFFF",
              borderColor: "#3A3A3A"
            },
            authDropdownTheme: {
              ...UserAuthDropdown.theme.light,
              buttonPrimaryColor: UserAuthDropdown.theme.dark.buttonPrimaryColor
            }
          },
          ham: {...CustomHamMenu.theme.light, hamIcon: CustomHamMenu.theme.dark.hamIcon}
        }}
        socialLinks={customTemplateConfig.socialLinks}
      />
      {customTemplateConfig.sections.map(renderSection)}
      <CreatorFooter
        isLightMode={true}
        bgColor="[#F1EBE9]"
        fgColor="[#333333]"
      />
    </div>
  )
}

export default Template10;