import cls from "classnames";
import ButtonAppear from "components/Animation/button/ButtonAppear";
import ButtonHover from "components/Animation/button/ButtonHover";
import TranslateAppear from "components/Animation/TranslateAppear";
import Link from "next/link";
import React, { useState } from "react";
import Title from "./Title";

const TestimonialCard = ({ customer, review, index }) => (
  <div
    className={cls(
      index & 1 ? "bg-[#FDF3E7]" : "bg-[#E9E1DF]",
      "rounded-lg w-full",
      "flex flex-col justify-between",
      "h-full w-full",
      "shadow-[4px_4px_4px_0px_#00000026]"
    )}
  >
    <div
      className="italic text-sm lg:text-base text-[#333333] p-4"
      dangerouslySetInnerHTML={{ __html: review }}
    ></div>
    <div className="px-4 py-5 md:py-[1.375rem] flex items-center justify-between">
      <div
        className="font-bold text-[#333333] text-base md:text-xl"
        dangerouslySetInnerHTML={{ __html: customer }}
      ></div>
      <div className="flex items-center">
        <div className="h-7 lg:h-9">
          <img
            src="/assets/icons/blockquote.svg"
            className="h-full w-full object-cover"
          />
        </div>
        <div className="h-7 lg:h-9">
          <img
            src="/assets/icons/blockquote.svg"
            className="h-full w-full object-cover"
          />
        </div>
      </div>
    </div>
  </div>
);

const Reviews = ({ id, creator, data }) => {
  const [readMoreCounter, setReadMoreCounter] = useState(1);

  const reviewsPerLoad = 3; // no of reviews to load when read more is clicked

  const loadMore = () => {
    setReadMoreCounter((prev) => prev + 1);
  };

  const button = (
    <ButtonAppear className="w-fit mt-6 md:mt-9">
      <ButtonHover className="w-fit flex">
        <Link
          className={cls(
            "w-fit flex justify-center items-center rounded-lg",
            "font-bold px-10 py-4 text-2xl leading-normal",
            "bg-[#3A3A3A] text-[#ffffff]",
            "md:flex hidden"
          )}
          href={data.button.link}
          dangerouslySetInnerHTML={{ __html: data.button.label }}
        ></Link>
        <span
          className={cls(
            "md:hidden flex underline text-sm text-[#333333] cursor-pointer",
            readMoreCounter * reviewsPerLoad >= data.items.length && "hidden"
          )}
          onClick={loadMore}
        >
          read more
        </span>
      </ButtonHover>
    </ButtonAppear>
  );

  return (
    <section
      id={id}
      className="w-full overflow-hidden flex flex-col items-center relative z-0 py-9 lg:py-[3.75rem] px-5 md:px-10 lg:px-[7.5rem]"
    >
      <Title title={data.title} />
      <div className="w-full flex items-stretch justify-center gap-9 flex-wrap mt-9">
        {data.items.map((review, index) => {
          return (
            <TranslateAppear
              key={index}
              delayInStart={index * 75}
              className={cls(
                "flex-1 items-center justify-center min-w-[320px] max-w-[33%]",
                index < readMoreCounter * reviewsPerLoad
                  ? "flex"
                  : "hidden md:flex"
              )}
            >
              <TestimonialCard
                customer={review.customer}
                review={review.review}
                index={index}
              />
            </TranslateAppear>
          );
        })}
      </div>
      {button}
    </section>
  );
};

export default Reviews;
