import {useState} from "react";
import { InView } from "react-intersection-observer";
import cls from "classnames";

export default function TranslateAppear({className, style, children, rootMargin="-10% 0% -10% 0%", delayInStart=0}) {
  const [animate, setAnimate] = useState(false);

  function triggerAnimation() {
    setAnimate(true);
  }

  return (
    <InView
      onChange={(inView) => {
        if (inView) {
          triggerAnimation();
        }
      }}
      rootMargin={rootMargin}
      className={cls(
        className,
        "transition-all",
        { "translate-y-8 opacity-0": !animate },
        {
          "translate-y-0 opacity-100 block": animate,
        }
      )}
      style={{
        transitionTimingFunction: 'cubic-bezier(0.19, 1, 0.61, 1.11)',
        transitionDuration: '0.6s',
        transitionDelay: `${delayInStart}ms`,
        ...style
      }}
    >
      {children}
    </InView>
  );
}