import cls from "classnames";
import Link from "next/link";
import ButtonHover from "components/Animation/button/ButtonHover";

const VerticalCard = ({
  title,
  description,
  link,
  image,
  buttonLabel,
  buttonIcon,
  className,
}) => {
  return (
    <div
      className={cls(
        "flex flex-col items-center text-center bg-[#F1EBE9] shadow-[4px_4px_4px_0px_#00000026] rounded-[0.25rem] overflow-hidden",
        "px-3 lg:py-6 lg:px-4",
        "lg:w-[374px] lg:h-[508px]",
        title ? "w-[308px] h-[400px] py-3 sm:w-[308px] sm:h-[440px]" : "py-5 w-[296px] h-[324px] sm:w-[308px] sm:h-[372px]",
        "gap-3 lg:gap-6",
        className
      )}
    >
      <div
        className={cls(
          "lg:h-60 lg:w-60 flex-shrink-0",
          title ? "h-[208px] w-[208px]" : "h-[160px] w-[160px]",
          "rounded-[0.25rem] overflow-hidden"
        )}
      >
        <img src={image} className="h-full w-full object-cover" />
      </div>
      {title && (
        <div className="text-base font-bold text-[#B27F6F] line-clamp-1 text-ellipsis">{title}</div>
      )}
      <div
        className={cls(
          "text-sm sm:text-base lg:text-xl w-full",
          "text-[#333333] text-ellipsis line-clamp-3 sm:line-clamp-4"
        )}
        dangerouslySetInnerHTML={{__html: description}}
      >
      </div>
      <ButtonHover className={cls("mt-auto", title ? "w-full" : "w-fit")}>
        <Link
          className={cls(
            "flex justify-center items-center rounded-lg",
            "font-bold text-sm sm:text-base lg:text-xl",
            "bg-[#3A3A3A] text-[#ffffff]",
            title ? "py-[0.625rem] lg:p-4 w-full" : "py-2 px-4 md:py-[0.625rem] lg:px-6 lg:py-3 w-fit"
          )}
          href={link}
          target="_blank"
        >
          {buttonLabel}
          {buttonIcon && <img src={buttonIcon} className="w-6 h-6 ml-2" />}
        </Link>
      </ButtonHover>
    </div>
  );
};

export default VerticalCard;
