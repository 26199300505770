import React, { useMemo, useState, useRef, useEffect } from "react";
import cls from "classnames";
import { partitionArray } from "@qoohoo/common/utils/object";
import useScreenSize from "@qoohoo/common/hooks/useScreenSize";
import TranslateAppear from "components/Animation/TranslateAppear";
import Title from "./Title";
import HorizontalCard from "./HorizontalCard";
import VerticalCard from "./VerticalCard";
import ButtonAppear from "components/Animation/button/ButtonAppear";
import ButtonHover from "components/Animation/button/ButtonHover";
import { ArrowIcon } from "components/KYCIcons";
import Banner from "./Banner";

function parseText(text){
  let _text = text;

  _text = _text.replace(/\n/g, "<br/>");

  return _text;
}

const RowWrapper = ({ children, title, className }) => (
  <div className={cls("w-full", className)}>
    <div
      className={cls(
        "text-[#333333] text-center",
        "font-extrabold md:font-bold",
        "text-base leading-5 sm:text-2xl lg:text-[2rem] lg:leading-[2.75rem]",
        "mb-4 md:mb-9"
      )}
    >
      {title}
    </div>
    {children}
  </div>
);

const HorizontalListWrapper = ({ children, title }) => {
  const scrollRef = useRef(null);

  const scrollRight = () => {
    if(scrollRef && scrollRef.current) {
      const scrollLimit = scrollRef.current.scrollWidth;

      scrollRef.current.scrollLeft = Math.min(scrollRef.current.scrollLeft + 600, scrollLimit);
    }
  };

  const scrollLeft = () => {
    if(scrollRef && scrollRef.current) {   
      scrollRef.current.scrollLeft = Math.max(0, scrollRef.current.scrollLeft - 600);   
    }
  };
 
  return (
    <div className={"w-full mt-12"}>
    <div className="w-full px-12 flex items-center justify-between mb-4 md:mb-9">
      <div className="flex-1"></div>
      <div className={cls(
        "text-[#333333] text-center font-extrabold md:font-bold",
        "text-base leading-5 sm:text-2xl lg:text-[2rem] lg:leading-[2.75rem]",
      )}>{title}</div>
      {/* Scroll Controls */}
      <div className="flex flex-1 items-center justify-end relative gap-2">
        <div className="cursor-pointer h-8 w-8 lg:h-11 lg:w-11 rounded-full flex items-center justify-center bg-[#B27F6F] rotate-180" onClick={scrollLeft}><ArrowIcon /></div>
        <div className="cursor-pointer h-8 w-8 lg:h-11 lg:w-11 rounded-full flex items-center justify-center bg-[#B27F6F]" onClick={scrollRight}><ArrowIcon /></div>
      </div>
    </div>
    <div className="w-full overflow-hidden">
      <div ref={scrollRef} className="flex gap-9 px-[3.75rem] w-full overflow-y-scroll pb-2 hide-scrollbar scroll-smooth snap-x snap-mandatory">
        {children}
      </div>
    </div>
  </div>
  );
}

const VerticalListWrapper = ({ children, title }) => (
  <RowWrapper
    className={cls("px-6 lg:px-[7.5rem]", "mt-6 md:mt-10")}
    title={title}
  >
    <div
      className={cls(
        "flex flex-wrap items-stretch justify-center",
        "gap-4 md:gap-6"
      )}
    >
      {children}
    </div>
  </RowWrapper>
);

const RowRenderer = ({ meta, isLast, entities, numItemsToRender }) => {
  const screenSize = useScreenSize();
  
  const type = meta.type.toLowerCase();
  const entitiesToRender = entities[type];

  const isSmallScreen = ["sm", "xs"].indexOf(screenSize) > -1;

  if (!entitiesToRender) return null;

  let content;

  if (type === "channels") {
    let _Card, _Wrapper;
    if (!isSmallScreen) {
      _Card = HorizontalCard;
      _Wrapper = HorizontalListWrapper;
    } else {
      _Card = VerticalCard;
      _Wrapper = VerticalListWrapper;
    }
    content = (
      <_Wrapper title={meta.title}>
        {entitiesToRender.map((entity, index) => {
          if (index + 1 <= numItemsToRender || !isSmallScreen) {
            return (
              <_Card
                key={entity.uid}
                title={entity.name}
                buttonLabel={meta.button.label}
                buttonIcon={meta.button.icon}
                link={entity.short_url_flutter}
                description={entity.description}
                image={entity.image}
              />
            );
          } else {
            return null;
          }
        })}
      </_Wrapper>
    );
  } else if (type === "premium_contents") {
    content = (
      <VerticalListWrapper title={meta.title}>
        {entitiesToRender.map((entity, index) => {
          const image =
            entity.preview_img?.url || "/assets/images/teaser_text_720x432.jpeg";

          const description = parseText(entity.teaser_text);

          if (index + 1 <= numItemsToRender || !isSmallScreen) {
            return (
              <VerticalCard
                key={entity.uid}
                buttonLabel={meta.button.label}
                buttonIcon={meta.button.icon}
                link={entity.short_url}
                description={description}
                image={image}
              />
            );
          } else {
            return null;
          }
        })}
      </VerticalListWrapper>
    );
  }

  return (
    <TranslateAppear className={cls("w-full relative")}>
      {content}
      {!isLast && (
        <div
          className={cls(
            "h-[770px] md:h-[526px]",
            "absolute translate-y-1/2 -z-10 bg-[#FDF3E7] w-full",
            "-bottom-9 md:-bottom-12"
          )}
        ></div>
      )}
    </TranslateAppear>
  );
};

const Offerings = ({ id, creator, data }) => {
  const [numItemsToRender, setNumItemsToRender] = useState(6);

  const loadMore = () => {
    setNumItemsToRender((prev) => prev + 6);
  };

  const entities = creator.entities;

  const maxItemsCount = useMemo(() => {
    let _max = 0;

    data.items.forEach((item) => {
      const items = entities[item.type.toLowerCase()];
      if (items) _max = Math.max(_max, items.length);
    });

    return _max;
  }, [entities]);

  return (
    <section
      id={id}
      className={cls("relative z-0 w-full", "py-9 md:py-[3.75rem]")}
    >
      {/* Title */}
      <div className={cls("w-full", "px-8 lg:px-[7.5rem]")}>
        <Title title={data.title} />
      </div>

      {/* Banner */}
      <Banner data={data} />

      {/* Courses */}
      {data.items.map((item, index) => {
        const isLast = index == data.items.length - 1;

        return (
          <RowRenderer
            key={index}
            meta={item}
            isLast={isLast}
            entities={entities}
            numItemsToRender={numItemsToRender}
          />
        );
      })}
      {numItemsToRender < maxItemsCount ? (
        <div
          className={cls(
            "flex md:hidden",
            "w-full mt-6 justify-center",
            "px-8 lg:px-[7.5rem]"
          )}
        >
          <ButtonAppear className={cls("block md:hidden", "w-fit")}>
            <ButtonHover className={cls("w-fit")}>
              <div
                onClick={loadMore}
                className={cls(
                  "cursor-pointer px-6 py-2 select-none flex justify-center items-center rounded-lg",
                  "font-bold text-sm",
                  "bg-[#3A3A3A] text-[#ffffff]"
                )}
              >
                See more
              </div>
            </ButtonHover>
          </ButtonAppear>
        </div>
      ) : null}
    </section>
  );
};

export default Offerings;
