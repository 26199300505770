import cls from "classnames";
import React from "react";
import TranslateAppear from "components/Animation/TranslateAppear";

const Title = ({ title }) => {
  return (
    <TranslateAppear className="w-full relative flex items-center">
      <div className="h-[1px] bg-[#B27F6F] flex-1" />
      <h1
        className={cls(
          "bg-[#B27F6F] relative text-[#ffffff] w-fit flex-shrink-0 min-w-0 shadow-[4px_4px_4px_0px_#00000026]",
          "mx-2 md:mx-6 lg:mx-9",
          "px-[3px] sm:px-2 lg:px-4 py-1 sm:py-2 lg:py-3",
          "text-xl sm:text-3xl lg:text-5xl",
          "leading-normal lg:leading-[3.25rem]"
        )}
        style={{ fontFamily: "Oregano" }}
        dangerouslySetInnerHTML={{ __html: title }}
      ></h1>
      <div className="h-[1px] bg-[#B27F6F] flex-1" />
    </TranslateAppear>
  );
};

export default Title;
