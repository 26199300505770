import cls from "classnames";
import TranslateAppear from "components/Animation/TranslateAppear";
import React from "react";
import Title from "./Title";

const Collage = ({ id, creator, data }) => {
  const mediaGrid = (
    <div
      className={cls(
        "w-full mt-9 bg-[#ffffff] overflow-hidden",
        "p-3 md:py-8 md:px-11",
        "h-[560px] sm:h-[640px] md:h-[560px]"
      )}
    >
      <div
        className={cls(
          "w-full h-full overflow-hidden grid rounded-[0.25rem]",
          "grid-rows-[minmax(0,14fr)_minmax(0,14fr)_minmax(0,24fr)] grid-cols-[minmax(0,5fr)_minmax(0,9fr)] md:grid-rows-2 md:grid-cols-[24fr_11fr_18fr]",
          "gap-x-3 gap-y-2 md:gap-x-4 md:gap-y-5 lg:gap-x-6 lg:gap-y-8"
        )}
      >
        <TranslateAppear
          delayInStart={50}
          className={cls("row-span-1", "col-span-2 md:col-span-1")}
        >
          <img
            src={data.items[0].url}
            className={cls(
              "w-full h-full object-cover overflow-hidden rounded-md",
              "object-center sm:object-top md:object-center"
            )}
          />
        </TranslateAppear>
        <TranslateAppear
          delayInStart={50}
          className={cls(
            "row-span-1",
            "col-span-2 md:col-span-1",
            "row-start-auto md:row-start-2"
          )}
        >
          <img
            src={data.items[1].url}
            className={cls(
              "w-full h-full object-cover overflow-hidden rounded-md",
              "object-center sm:object-top md:object-center"
            )}
          />
        </TranslateAppear>
        <TranslateAppear
          delayInStart={150}
          className={cls("col-span-1", "row-span-1 md:row-span-2")}
        >
          <img
            src={data.items[2].url}
            className={cls(
              "w-full h-full object-cover overflow-hidden rounded-md",
              "object-center sm:object-top md:object-center"
            )}
          />
        </TranslateAppear>
        <TranslateAppear
          delayInStart={200}
          className={cls("col-span-1", "row-span-1 md:row-span-2")}
        >
          <img
            src={data.items[3].url}
            className={cls(
              "w-full h-full object-cover overflow-hidden rounded-md",
              "object-center sm:object-top md:object-center"
            )}
          />
        </TranslateAppear>
      </div>
    </div>
  );

  return (
    <section
      id={id}
      className={cls(
        "w-full overflow-hidden bg-[#F1EBE9]",
        "py-9 md:py-[3.75rem] px-5 lg:px-[7.5rem]"
      )}
    >
      <Title title={data.title} />
      {mediaGrid}
    </section>
  );
};

export default Collage;
