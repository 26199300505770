import { useMemo, useEffect } from "react";

const createLink = (fonts, text) => {
  const families = fonts
    .reduce((acc, font) => {
      const family = font.fontFamily.replace(/ +/g, "+");
      const weights = (font.weights || []).join(",");

      return [...acc, family + (weights && `:${weights}`)];
    }, [])
    .join("&family=");

  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = `https://fonts.googleapis.com/css2?family=${families}`;

  if(text) {
    link.href = link.href + `&text=${encodeURIComponent(text)}`;
  }

  return link;
};

const useGoogleFonts = (fonts, text) => {
  if(typeof document === "undefined")
    return null;

  const link = useMemo(()=>createLink(fonts, text), [JSON.stringify(fonts), text]);
  
  useEffect(() => {
    document.head.appendChild(link);

    return () => document.head.removeChild(link);
  }, [link]);

  return null;
};

export default useGoogleFonts;
