import React, { useCallback, useState } from "react";
import cls from "classnames";
import { Controlled as ControlledZoom } from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import useScreenSize from "@qoohoo/common/hooks/useScreenSize";
import TranslateAppear from "components/Animation/TranslateAppear";

const WorkSampleCard = ({ type, url, mediaCreator, index }) => {
  const screenSize = useScreenSize();

  const [isZoomed, setIsZoomed] = useState(false);

  const handleImgLoad = useCallback(() => {
    setIsZoomed(true);
  }, []);

  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom);
  }, []);

  const isSmallScreen = screenSize === "xs" || screenSize === "sm";

  return (
    <TranslateAppear
      className={cls("w-fit h-fit flex flex-col items-center")}
      delayInStart={index * 50}
    >
      <div
        className={cls(
          "overflow-hidden shadow-[4px_4px_4px_0px_#00000026] rounded-[0.25rem]",
          "h-[280px] w-[280px] sm:w-[330px] sm:h-[330px]"
        )}
      >
        <ControlledZoom
          isZoomed={isZoomed}
          onZoomChange={handleZoomChange}
          overlayBgColorEnd="#19191d"
          overlayBgColorStart="#19191d"
          zoomMargin={isSmallScreen ? 0 : 50}
          wrapStyle={{
            width: "100%",
            height: "100%",
          }}
        >
          <img
            className={cls(
              "h-full w-full select-none",
              isZoomed ? "object-contain" : "object-cover"
            )}
            src={url}
          />
        </ControlledZoom>
      </div>
      <div
        className={cls(
          "bg-[#3a3a3a] text-[#ffffff] text-center flex flex-col items-center mt-4 w-full rounded-[0.25rem] max-w-[360px]",
          "px-4 py-2 md:px-5 md:py-3"
        )}
      >
        <span
          className={cls(
            "text-sm md:text-base",
            "font-semibold md:font-normal"
          )}
        >
          Courtesy:{" "}
        </span>
        <span
          className={cls(
            "leading-normal",
            "font-semibold md:font-bold",
            "text-xl md:text-2xl"
          )}
          dangerouslySetInnerHTML={{ __html: mediaCreator }}
        />
      </div>
    </TranslateAppear>
  );
};

const WorkSamples = ({ id, creator, data }) => {
  return (
    <section
      id={id}
      className={cls(
        "w-full overflow-hidden relative z-0 flex gap-8 justify-center items-center flex-wrap",
        "px-16 md:px-[7.5rem] pt-5 md:pt-[2.875rem] pb-9 md:pb-[5.625rem]"
      )}
    >
      {data.items.map((sample, index) => (
        <WorkSampleCard
          key={index}
          index={index}
          mediaCreator={sample.creator}
          url={sample.url}
          type={sample.type}
        />
      ))}
    </section>
  );
};

export default WorkSamples;
