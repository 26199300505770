import {useState} from "react";
import { InView } from "react-intersection-observer";
import cls from "classnames";

export default function ButtonAppear({className, rootMargin = "-10% 0% -15% 0%", delayInStart = 0, children}) {
  const [animate, setAnimate] = useState(false);

  function triggerAnimation() {
    setAnimate(true);
  }

  return (
    <InView
      onChange={(inView) => {
        if (inView) {
          triggerAnimation();
        }
      }}
      rootMargin={rootMargin}
      className={cls(
        className,
        "transition-all",
        { "scale-110": !animate },
        {
          "scale-100 block": animate,
        }
      )}
      style={{
        transitionTimingFunction: 'cubic-bezier(.3,1.35,1,1)',
        transitionDuration: '120ms',
        transitionDelay: `${delayInStart}ms`
      }}
    >
      {children}
    </InView>
  );
}
