import cls from "classnames";
import Link from "next/link";
import ButtonHover from "components/Animation/button/ButtonHover";

function Banner({ id, creator, data }) {
  return (
    <div
      className={cls(
        "w-full bg-[#FDF3E7] mt-12 flex md:flex-row flex-col items-center justify-between px-8 lg:px-[160px] gap-4 py-5 md:py-0"
      )}
      style={{
        boxShadow: "4px 4px 4px 0px #00000026",
      }}
    >
      <div
        className={cls(
          "flex flex-col items-center md:items-start justify-center gap-1 md:my-5"
        )}
      >
        <span className="text-[#3A3A3A] text-lg sm:text-xl lg:text-[22px] lg:leading-9 font-bold text-center md:text-left">
          {data.banner.title}
        </span>
        <span className="text-[#3A3A3A] text-sm lg:text-base text-center md:text-left">
          {data.banner.description}
        </span>
      </div>
      <ButtonHover className={cls("w-fit my-3 md:my-auto")}>
        <Link
          className={cls(
            "font-bold w-fit whitespace-nowrap rounded-lg",
            "py-[0.625rem] px-4 lg:py-3 lg:px-9",
            "text-base lg:text-xl",
            "bg-[#3A3A3A] text-[#ffffff]"
          )}
          href={data.banner.button.link}
          target="_blank"
        >
          {data.banner.button.label}
        </Link>
      </ButtonHover>
    </div>
  );
}

export default Banner;
