import { Menu } from "@headlessui/react";
import cls from "classnames";

import * as Track from "@qoohoo/common/utils/analytics/track";

import HamMenuLayout from "components/HamMenu/HamMenuLayout";
import { HamAuthComponent } from "components/HamMenu/HamMenuMain";
import Link from "next/link";

export default function CustomHamMenu({
  currentScreen,
  showAuth = true,
  theme = CustomHamMenu.theme.dark,
  navItems = null,
  socialLinks = [],
}) {
  const hamIcon = () => (
    <img className="select-none" src={theme.hamIcon} alt="Menu button icon" />
  );

  const hamAuthTheme = theme.auth;

  function renderSocialLinks() {
    if(!socialLinks || socialLinks?.length == 0) return;

    return (
      <Menu.Item as="div" className="flex flex-row items-center justify-start gap-2">
            {socialLinks.map((link, index) => (
              <div key={index}>
                <Link
                  href={link.url}
                  target="_blank"
                  rel="noopener"
                  className={"brightness-75 hover:brightness-90"}
                  onClick={() => {
                    Track.linkClick(link.trackLabel, {
                      url: link.url,
                      currentScreen,
                    });
                  }}
                >
                  <img
                    className={cls(
                      "relative btn-hover-bg h-6 hover:opacity-80",
                      "cursor-pointer",
                      theme.linkHoverClass
                    )}
                    src={link.icon}
                    alt={link.alt}
                    style={{
                      WebkitFilter: "grayscale(100%) brightness(0)",
                      filter: "grayscale(100%) brightness(0)",
                    }}
                  />
                </Link>
              </div>
            ))}
      </Menu.Item>
    );
  }

  return (
    <HamMenuLayout
      navItems={navItems}
      currentScreen={currentScreen}
      hamIcon={hamIcon}
      theme={theme}
      hamAuthComponent={(props) =>
        showAuth ? <HamAuthComponent theme={hamAuthTheme} {...props} /> : null
      }
      extraComponent={renderSocialLinks}
    />
  );
}

CustomHamMenu.theme = {
  light: {
    primaryColor: "#16191B",
    background: "#ffffff",
    borderColor: "#D3D5D7",
    hamIcon: "/assets/icons/menu-dark.svg",
    auth: HamAuthComponent.theme.light,
    linkHoverClass: "btn-hover-bg btn-hover-bg-light",
  },
  dark: {
    primaryColor: "#ffffff",
    background: "#272B30",
    borderColor: "#292E33",
    hamIcon: "/assets/icons/menu.svg",
    auth: HamAuthComponent.theme.dark,
    linkHoverClass: "btn-hover-bg",
  },
};
