import cls from "classnames";
import Link from "next/link";
import ButtonAppear from "components/Animation/button/ButtonAppear";
import ButtonHover from "components/Animation/button/ButtonHover";
import TranslateAppear from "components/Animation/TranslateAppear";
import React from "react";

const Hero = ({ id, creator, data }) => {
  const title = (
    <TranslateAppear>
      <h1
        className={cls(
          "text-[#B27F6F]",
          "text-center md:text-left",
          "text-2xl leading-normal md:text-3xl lg:text-4xl lg:leading-[1.44]"
        )}
        style={{ fontFamily: "Oregano" }}
        dangerouslySetInnerHTML={{ __html: data.title }}
      ></h1>
    </TranslateAppear>
  );
  const description = (
    <TranslateAppear delayInStart={100}>
      <div
        className={cls(
          "text-[#333333] mt-5  border-l-[#B27F6F]",
          "text-center md:text-left",
          "text-base md:text-xl lg:text-2xl lg:leading-normal",
          "pl-0 md:pl-6",
          "border-l-0 md:border-l-2"
        )}
        dangerouslySetInnerHTML={{ __html: data.description }}
      ></div>
    </TranslateAppear>
  );

  const featureImg = (
    <div
      className={cls(
        "w-full h-full rounded-[0.25rem] overflow-hidden shadow-[4px_4px_4px_0px_#00000026]"
      )}
      style={{ aspectRatio: 0.822 }}
    >
      <img
        src={data.image}
        className={cls(
          "h-full w-full object-cover rounded-[0.25rem] overflow-hidden"
        )}
      />
    </div>
  );

  const mainSection = (
    <main
      className={cls(
        "flex flex-col flex-1 py-0 mt-5",
        "items-center md:items-start",
        "min-w-[55%]",
        "md:mt-0 md:py-[3.625rem]"
      )}
    >
      {title}
      {description}
      <ButtonAppear className="flex mt-5 w-fit" delayInStart={150}>
        <ButtonHover className="flex w-fit">
          <Link
            className={cls(
              "w-fit flex font-bold justify-center items-center rounded-lg",
              "p-3 md:px-5 lg:px-10 lg:py-4 text-base md:text-xl lg:text-2xl md:leading-normal",
              "bg-[#3A3A3A] text-[#ffffff]"
            )}
            href={data.button.link}
            dangerouslySetInnerHTML={{ __html: data.button.label }}
          ></Link>
        </ButtonHover>
      </ButtonAppear>
    </main>
  );

  const asideSection = (
    <TranslateAppear delayInStart={50} className="flex-shrink">
      <aside
        className={cls(
          "hidden md:flex",
          "bg-[#B27F6F] rounded-[0.25rem] overflow-hidden",
          "px-[1.125rem] py-6",
          "ml-10 xl:ml-[7.5rem]"
        )}
      >
        {featureImg}
      </aside>
    </TranslateAppear>
  );

  return (
    <main
      id={id}
      className={cls(
        "relative z-0 flex w-full items-center bg-[#F1EBE9]",
        "justify-start md:justify-between",
        "flex-col md:flex-row",
        "px-5 py-9 lg:px-[6.75rem] lg:py-[3.75rem]"
      )}
    >
      <div
        className={cls(
          "bg-[#B27F6F] justify-center px-[0.625rem] py-3 rounded-[0.25rem] overflow-hidden",
          "flex md:hidden",
          "h-60 sm:h-80"
        )}
      >
        {featureImg}
      </div>
      {mainSection}
      {asideSection}
      <img
        src={data.accentIcon}
        className={cls(
          "hidden md:block",
          "absolute right-0 bottom-0 translate-y-3/4 z-10"
        )}
      />
      <img
        src={data.accentIconSmall}
        className={cls(
          "block md:hidden",
          "absolute right-0 bottom-0 translate-y-3/4 z-10"
        )}
      />
    </main>
  );
};

export default Hero;
