import cls from "classnames";
import Link from "next/link";
import ButtonHover from "components/Animation/button/ButtonHover";

const HorizontalCard = ({
  title,
  description,
  link,
  image,
  buttonLabel,
  buttonIcon,
}) => {
  return (
    <div
      className={cls(
        "p-7 lg:p-10 shrink-0 snap-center",
        "w-[640px] h-[282px] lg:w-[880px] lg:h-[388px]",
        "flex gap-6 bg-[#F1EBE9] shadow-[4px_4px_4px_0px_#00000026] rounded-[1.25rem] overflow-hidden"
      )}
    >
      <div
        className={cls(
          "h-[222px] w-[222px] lg:h-[308px] lg:w-[308px] shrink-0",
          "rounded-[0.25rem] overflow-hidden"
        )}
      >
        <img src={image} className={cls("h-full w-full object-cover")} />
      </div>
      <div className={cls("flex flex-col flex-1 py-1", "gap-2 lg:gap-4")}>
        <div
          className={cls(
            "text-[#B27F6F] line-clamp-2 text-ellipsis",
            "text-xl lg:text-[1.75rem] lg:leading-10 font-bold"
          )}
        >
          {title}
        </div>
        <div
          className={cls(
            "leading-normal text-[#333333] text-ellipsis line-clamp-4",
            "text-base lg:text-xl"
          )}
        >
          {description}
        </div>
        <ButtonHover className={cls("w-fit mt-auto")}>
          <Link
            className={cls(
              "font-bold w-fit flex justify-center items-center rounded-lg",
              "py-[0.625rem] px-4 lg:py-4 lg:px-[3.75rem]",
              "text-base lg:text-xl",
              "bg-[#3A3A3A] text-[#ffffff]"
            )}
            href={link}
            target="_blank"
          >
            {buttonLabel}
            {buttonIcon && (
              <img
                src={buttonIcon}
                className={cls("lg:w-6 lg:h-6", "ml-1 lg:ml-2", "w-4 h-4")}
              />
            )}
          </Link>
        </ButtonHover>
      </div>
    </div>
  );
};

export default HorizontalCard;
